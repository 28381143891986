document.addEventListener('DOMContentLoaded', function () {

  // menu handler
  var menuHandler = document.getElementById("menuHandler");
  if (menuHandler) {
    menuHandler.addEventListener('click', function (event) {
        event.preventDefault()
        var mainSidebarNavigation = document.getElementById("main-sidebar-navigation");

        var position = mainSidebarNavigation.getBoundingClientRect();
        if(position.left < 0)
        {
          mainSidebarNavigation.style.left = 0;
          document.body.style.overflow = "hidden";

        }else{
          mainSidebarNavigation.style.left = "-"+100+"%";
          document.body.style.overflow = "auto";  
        }

        document.getElementById('closeMenuIcon').classList.toggle('d-none');
        document.getElementById('openMenuIcon').classList.toggle('d-none');
    });
  }

});